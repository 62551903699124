/* About Us */
.about-year{
	padding: 25px;
	width: 240px;
	position: absolute;
	bottom: 30px;
	left: 0;
	box-shadow: 0 10px 50px -20px $primary;
	background: $primary;
	-webkit-animation: up-down 2s infinite  alternate;
	animation: up-down  2s infinite  alternate;
	.count{
		font-size:90px;
		color:$white;
		line-height: 90px;
		margin-bottom: 5px;
	}
	.text{
		color:$white;
		font-weight: 700;
		text-transform: uppercase;
		box-shadow: inset 0 -3px 0px 0 $warning;
	    display: inline-block;	
		padding-bottom: 5px;
		margin-bottom: 20px;
	}
	@include respond('phone'){
		padding: 15px;
		width: 195px;
		bottom: 15px;
		.count{
			font-size: 60px;
			line-height: 60px;
		}
	}
}
.about-imgbox{
	padding-left:90px;	
	position:relative;
}
.videoplay-bx{
	i {
		width: 50px;
		height: 50px;
		background: $warning;
		border-radius: 40px;
		text-align: center;
		line-height: 50px;
		color: $black;
		padding-left: 3px;
	}
	 span {
		font-weight: 700;
		text-transform: uppercase;
		color: $white;
		margin-left: 10px;
	}
}

// Counter
.counter-style-1{
	padding:20px;
	border: 2px solid #eee;
	position:relative;
	margin-bottom: 30px;
	
	&:after{
		content: "";
		border-top: 15px solid $primary;
		border-left: 15px solid transparent;
		position: absolute;
		right: 5px;
		top: 5px;
	}
}
/* .counter-style-2{
	text-align:center;
}
.counter-style-2 .counter-bx{
	margin-bottom:10px;
}
.counter-style-2 .counter-bx span{
	font-size:60px;
	font-weight:500;
	line-height: 70px;
	display: inline-block;
	color:#000;
}
.counter-style-2 .counter-text{
	font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #627696;
    text-transform: uppercase;
}
.text-white .counter-style-2 .counter-text,
.text-white .counter-style-2 .counter-bx span{
	color:#fff;
} */
.btn {
    padding: 7px 20px;
    display: inline-block;
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
	font-family:$font-family-title;
}
.btn-primary{
    color: #fff;
	&:hover{
		color: #fff;
	}
}
.btn-outline-primary{
	&:hover{
		color: #fff;
	}
}
.btn.btn-lg{
	padding: 10px 35px;
    font-size: 16px;
}
.btn-link{
	font-weight: 600;
	color: $primary;
	font-size: 15px;
	border: 0;
	position: relative;
	text-decoration: none;
	
	&:after{
		content: "";
		height: 2px;
		width: 25px;
		position: absolute;
		background-color: $primary;
		bottom: 0;
		left: 0;
		@include durationMedium;
	}
	&:hover{
		text-decoration:none;
		&:after{
			width: 100%;
		}
	}
}
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/03/2020 11:59
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-repair-3:before { content: "\f100"; }
.flaticon-brake:before { content: "\f101"; }
.flaticon-gears:before { content: "\f102"; }
.flaticon-engine:before { content: "\f103"; }
.flaticon-turbo:before { content: "\f104"; }
.flaticon-tire:before { content: "\f105"; }
.flaticon-hammer:before { content: "\f106"; }
.flaticon-spray:before { content: "\f107"; }
.flaticon-wash:before { content: "\f108"; }
.flaticon-wheel:before { content: "\f109"; }
.flaticon-wheel-1:before { content: "\f10a"; }
.flaticon-charging:before { content: "\f10b"; }
.flaticon-battery:before { content: "\f10c"; }
.flaticon-electrical:before { content: "\f10d"; }
.flaticon-check:before { content: "\f10e"; }
.flaticon-gearshift:before { content: "\f10f"; }
.flaticon-chassis:before { content: "\f110"; }
.flaticon-radiator:before { content: "\f111"; }
.flaticon-check-1:before { content: "\f112"; }
.flaticon-crane:before { content: "\f113"; }
.flaticon-car:before { content: "\f114"; }
.flaticon-garage:before { content: "\f115"; }
.flaticon-check-2:before { content: "\f116"; }
.flaticon-car-1:before { content: "\f117"; }
.flaticon-car-2:before { content: "\f118"; }
.flaticon-gear:before { content: "\f119"; }
.flaticon-fuel:before { content: "\f11a"; }
.flaticon-spark:before { content: "\f11b"; }
.flaticon-headset:before { content: "\f11c"; }
.flaticon-gasoline:before { content: "\f11d"; }
.flaticon-repair:before { content: "\f11e"; }
.flaticon-car-3:before { content: "\f11f"; }
.flaticon-piston:before { content: "\f120"; }
.flaticon-lift:before { content: "\f121"; }
.flaticon-settings:before { content: "\f122"; }
.flaticon-tool:before { content: "\f123"; }
.flaticon-fuel-1:before { content: "\f124"; }
.flaticon-repair-1:before { content: "\f125"; }
.flaticon-steering-wheel:before { content: "\f126"; }
.flaticon-checklist:before { content: "\f127"; }
.flaticon-car-4:before { content: "\f128"; }
.flaticon-fuel-2:before { content: "\f129"; }
.flaticon-paint:before { content: "\f12a"; }
.flaticon-engine-1:before { content: "\f12b"; }
.flaticon-crane-1:before { content: "\f12c"; }
.flaticon-wheel-2:before { content: "\f12d"; }
.flaticon-lift-1:before { content: "\f12e"; }
.flaticon-car-5:before { content: "\f12f"; }
.flaticon-car-6:before { content: "\f130"; }
.flaticon-tyre:before { content: "\f131"; }
.flaticon-wheel-3:before { content: "\f132"; }
.flaticon-tools:before { content: "\f133"; }
.flaticon-battery-1:before { content: "\f134"; }
.flaticon-cogwheel:before { content: "\f135"; }
.flaticon-computer:before { content: "\f136"; }
.flaticon-car-wash:before { content: "\f137"; }
.flaticon-ignition:before { content: "\f138"; }
.flaticon-repair-2:before { content: "\f139"; }
.flaticon-vest:before { content: "\f13a"; }
.flaticon-tools-1:before { content: "\f13b"; }
.flaticon-speedometer:before { content: "\f13c"; }
.flaticon-car-7:before { content: "\f13d"; }
.flaticon-break:before { content: "\f13e"; }
.flaticon-speedometer-1:before { content: "\f13f"; }
.flaticon-gears-1:before { content: "\f140"; }
.flaticon-check-list:before { content: "\f141"; }
.flaticon-fuel-3:before { content: "\f142"; }
.flaticon-charging-1:before { content: "\f143"; }
.flaticon-battery-2:before { content: "\f144"; }
.flaticon-tools-2:before { content: "\f145"; }
.flaticon-truck:before { content: "\f146"; }
.flaticon-garage-1:before { content: "\f147"; }
.flaticon-car-8:before { content: "\f148"; }
.flaticon-settings-1:before { content: "\f149"; }
.flaticon-lift-2:before { content: "\f14a"; }
.flaticon-car-9:before { content: "\f14b"; }
.flaticon-spray-gun:before { content: "\f14c"; }
.flaticon-car-10:before { content: "\f14d"; }
.flaticon-gearstick:before { content: "\f14e"; }
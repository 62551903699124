// Scrolltop
button.back-to-top{
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    box-shadow: 2px 2px 12px -5px #000000;
    color: #fff;
	background-color: var(--primary);
    cursor: pointer;
    height: 45px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 45px;
    z-index: 99999;
    padding: 0;
    font-size: 12px;
	
	@include respond('phone'){
		height: 35px;
		width: 35px;
	}
}
.ttr-separator {
    display: inline-block;
    height: 4px;
    width: 90px;
    margin-bottom: 10px;
    position: relative;
	&.m-b0 {
		margin-bottom:0;
	}
	&.style-liner {
		width: 20px;
	}
	&.style-icon {
		width: 30px;
		height: auto;
		text-align: center;
		font-size: 20px;
	}
	&[class*="style-"]{
		&:after,
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 40px;
			width: 70px;
			height: 2px;
			background: #eee;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
		&:before {
			left: auto;
			right: 40px;
		}
	}
	&.bnr-title{
		height:1px;
		width: 155px;
		opacity: 0.5;
		&:before,
		&:after{
			position:absolute;
			content:"";
			background-color:inherit;
		}
		&:before {
			height: inherit;
			right: -80px;
			width: 25px;
		}
		&:after {
			height: inherit;
			right: -90px;
			top: 0;
			width: 6px;
		}
		i {
			background-color: inherit;
			display: block;
			height: inherit;
			position: absolute;
			right: -50px;
			width: 45px;
		}
	}
}
.ttr-separator-outer {
    overflow: hidden;
}
.form-group {
    margin-bottom: 1rem;
    position        : relative;

    label {
        font-size    : 14px;
        font-weight  : 500;
        color        : $body-color;
        margin-bottom: 10px;
        background   : transparent;
        border-color : $border-color;
    }
}

.form-control {
    border-radius: 0;
    border       : 1px solid transparent;
    padding      : 0px 15px;
    font-size    : 16px;
    font-weight  : 400;
    color        : $body-color;
    transition   : all 0.3s ease-in-out;
    background   : $body-bg;

    .select {
        padding: 12px 0;
    }

    span {
        margin-top: 0;
    }

    &::-webkit-input-placeholder {
        color: $body-color;
    }

    &:-ms-input-placeholder {
        color: $body-color;
    }

    &::placeholder {
        color: $body-color;
    }

    &:hover {
        box-shadow  : none;
        outline     : none;
        border-color: $border-color;
        color       : $body-color;
        background  : $body-bg;
    }

    &:focus,
    &:active,
    &.active {
        box-shadow  : none;
        outline     : none;
        border-color: $primary;
        color       : $body-color;
        background  : $body-bg;
    }
}

input:-internal-autofill-selected {
    background      : lighten($body-bg, 2%);
    background-image: none;
    color           : -internal-light-dark-color(black, white);
}

.input-group-text {
    padding         : 5px 15px;
    background      : $body-bg;
    margin-bottom   : 0px;
    color           : $body-color;
    border-color    : transparent;
}

.input-group-append {
    .input-group-text {
        border-top-right-radius   : 5px;
        border-bottom-right-radius: 5px;
    }
}

.input-group-prepend {
    .input-group-text {
        border-top-left-radius   : 5px;
        border-bottom-left-radius: 5px;
    }
}

//
.toggle {
    cursor          : pointer;
    display         : block;
}

.toggle-switch {
    display       : inline-block;
    background    : darken($body-bg, 3%);
    border-radius : 16px;
    width         : 45px;
    height        : 20px;
    position      : relative;
    vertical-align: middle;
    transition    : background 0.25s;

    &:before,
    &:after {
        content: "";
    }

    &:before {
        display      : block;
        background   : linear-gradient(to bottom, $white 0%, #eee 100%);
        border-radius: 50%;
        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
        width        : 17px;
        height       : 17px;
        position     : absolute;
        top          : 1px;
        left         : 3px;
        transition   : left 0.25s;
    }

    .toggle:hover &:before {
        background   : linear-gradient(to bottom, $white 0%, $white 100%);
        // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }

    .toggle-checkbox:checked+& {
        background: $primary;

        &:before {
            left: 26px;
        }
    }
}

.toggle-checkbox {
    position  : absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 15px;
    position   : relative;
    top        : 2px;
    font-size  : 16px;
    font-weight: 400;
}

// Upload btn
.file-upload-wrapper {
    position     : relative;
    width        : 100%;
    height       : calc(1.5em + 1rem + 2px);
    border       : 1px solid $border-color;
    border-radius: 5px;
    color        : $text-muted;

    &:after {
        content       : attr(data-text);
        // font-size  : 18px;
        position      : absolute;
        top           : 0;
        left          : 0;
        background    : $body-bg;
        padding       : 4px 15px;
        display       : block;
        width         : calc(100% - 40px);
        pointer-events: none;
        z-index       : 20;
        height        : calc(1.5em + 1rem + 2px);
        line-height   : 50px - 20px;
        // color      : #fff;
        border-radius : 5px 10px 10px 5px;
        font-weight   : 400;
        overflow      : hidden;
    }

    &:before {
        content : 'Upload';
        position: absolute;
        top     : 0;
        right   : 0;
        display : inline-block;
        height  : calc(1.5em + 1rem + 2px);
        ;
        background    : $primary;
        color         : $white;
        font-weight   : 400;
        z-index       : 25;
        font-size     : 14px;
        line-height   : 40px;
        padding       : 0 15px;
        text-transform: capitalize;
        pointer-events: none;
        border-radius : 0 5px 5px 0;
    }

    &:hover {
        &:before {
            background: darken($primary, 10%);
        }
    }

    input {
        opacity : 0;
        position: absolute;
        top     : 0;
        right   : 0;
        bottom  : 0;
        left    : 0;
        z-index : 99;
        height  : calc(1.5em + 1rem + 2px);
        margin  : 0;
        padding : 0;
        display : block;
        cursor  : pointer;
        width   : 100%;
    }
}

// Nice Select
.bootstrap-select {
	width:100%;
	.dropdown-toggle{
		width:100%;
		padding:10px 25px;
		font-family:$font-family-base;
		text-transform: none;
		background:#fff;
		font-size: 14px;
		outline: none ;
		&:focus{
			outline: none !important;
		}
		&:after{
			content:none;
		}
	}
	&.show.btn-group .dropdown-menu.inner{
		display:block;
	}	
	.dropdown-menu {
		border: 0;
		border-radius: 0;
		margin-top: -1px;
		padding: 10px 0;
		font-size: 14px;
		box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1);
		a{
			padding:6px 20px;
			display: block;
			color: #000;
			&:hover{
				background-color: $primary;
				color: #fff;
			}
		}
		.selected{
			a{
				background-color: $primary;
				color: #fff;
			}
		}
	}

}
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
	width:100%;
}
.bootstrap-select .list {
    background: $dark;
}
.bootstrap-select{
	&.form-control{
		padding: 0;
	}
}
// OWL SLIDER
// NEXT PRE BTN
.owl-none .owl-nav{
	display:none;
}
.owl-theme{

	.owl-nav,
	.owl-dots{
		margin-top: 20px;
		text-align: center;
	}
	.owl-nav .disabled {
		opacity: .5;
		cursor: default
	}
	.owl-dots{
		text-align:center;
		
		.owl-dot {
			display: inline-block;
			span {
				width: 10px;
				height: 10px;
				margin: 5px 7px;
				background: #d6d6d6;
				display: block;
				-webkit-backface-visibility: visible;
				-webkit-transition: opacity 200ms ease;
				-moz-transition: opacity 200ms ease;
				-ms-transition: opacity 200ms ease;
				-o-transition: opacity 200ms ease;
				transition: opacity 200ms ease;
				-webkit-border-radius: 30px;
				-moz-border-radius: 30px;
				border-radius: 30px
			}
			&:hover,
			&.active{
				span{
					background: #869791
				}
			}
		}
	}
}
.dots-style-1{
	.owl-dots{
		.owl-dot{
			display: inline-block;
			span{
				height: 12px;
				width: 12px;
				border-width: 2px;
				border-style: solid;
				border-color: rgba($dark,0.5);
				display: block;
				border-radius: 50px;
				margin: 6px;
				transition: all 0.5s;
			}
			&.active{
				span{
					border-color: $primary;
					border-width: 3px;
					transform: scale(1.2);
					transition: all 0.5s;
				}
			}
		}
	}
}
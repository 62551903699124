// help-bx
.help-bx{
	box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.1);
	.info{
		padding: 15px 25px 35px 25px;
		text-align: center;
		position: relative;
		z-index: 1;
		img{
			max-width: 60px;
			margin-bottom: 20px;
		}
		&:before,
		&:after{
			content: "";
			background: #fff;
			position: absolute;
			top: -25px;
			width: 100%;
			height: 80px;
			left: 0;
			transform: skewY(-8deg);
			z-index: -1;
		}
		&:before{
			background: #fff;
			top: -32px;
			transform: skewY(-5deg);
			height: 40px;
		}
	}
	p{
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
	}
}

// brochure
.brochure-bx{
	padding: 30px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: #fff;
    box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.1);
	
	.title-head {
		border-bottom: 1px solid rgba(0,0,0,0.15);
		padding-bottom: 8px;
		position: relative;
		margin-bottom: 30px;	
		&:after {
			background:var(--primary);
			content:"";
			width:30px;
			height:3px;
			position:absolute;
			bottom:-2px;
			left:0;
		}
	}
}
.download-link{
    position: relative;
    display: block;
    padding-left: 45px;	
    margin-top: 20px;	
	.title{
		font-size: 16px;
		margin-top: 0;
		margin-bottom: 0px;
		line-height: 22px;
	}
	span{
		color: #888;
		font-size: 14px;
		line-height: 18px;	
	}
	img{
		width: 45px;
		position: absolute;
		left: -5px;
		top: 0;
	}
}
#loading-icon-bx {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 999999999;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader{
	border-radius: 50%;
	color: $primary;
	font-size: 11px;
	text-indent: -99999em;
	margin: 55px auto;
	position: relative;
	width: 10em;
	height: 10em;
	box-shadow: inset 0 0 0 1em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);

	&:before,
	&:after {
		position: absolute;
		content: "";
	    border-radius: 50%;
	}
	&:before {
		width: 5.2em;
		height: 10.2em;
		background: #fff;
		border-radius: 10.2em 0 0 10.2em;
		top: -0.1em;
		left: -0.1em;
		-webkit-transform-origin: 5.1em 5.1em;
		transform-origin: 5.1em 5.1em;
		-webkit-animation: pageLoader 2s infinite ease 1.5s;
		animation: pageLoader 2s infinite ease 1.5s;
	}
	&:after {
		width: 5.2em;
		height: 10.2em;
		background: #fff;
		border-radius: 0 10.2em 10.2em 0;
		top: -0.1em;
		left: 4.9em;
		-webkit-transform-origin: 0.1em 5.1em;
		transform-origin: 0.1em 5.1em;
		-webkit-animation: pageLoader 2s infinite ease;
		animation: pageLoader 2s infinite ease;
	}
}
@-webkit-keyframes pageLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pageLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

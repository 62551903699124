footer{
	color:$ft-color;
	font-size:15px;
	
	.widget{
		margin-bottom:50px;
	}	
	strong{
		color:$ft-color;
	}	
	.footer-logo{
		max-width:180px;
		img{
			width:100%;
		}
		@include respond('phone'){
			margin: 0 10px;
		}
	}
	.widget-logo ul{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		img{
			opacity:0.9;
		}
		li{
			display:inline-block;
		    flex: 50%;	
		    padding: 0 15px 15px 0px;	
		}
	}
	.ttr-social-icon{
		li{
			display:inline-block;
			margin-right:20px;			
		}
		a{
			color:$ft-color;
			font-size:17px;
		}
	}
	.widget_about{
		p{
			margin-bottom:20px;
		}
	}
	.footer-title{
		margin: 0;
		font-weight: 500;
		margin-bottom: 30px;
		position: relative;
		color: $white;
		line-height: 1.2;
		text-transform: uppercase;
		&:after {
			content: "//";
			font-family: "Roboto", sans-serif;
			color: $primary;
			font-weight: 900;
			transform: scale(0.93) translateY(-3%);
			display: inline-block;
			margin-left: 5px;
		}
		@include respond('phone-land'){
			margin-bottom: 25px;
		}
	}
	.footer-top{
		background: $secondary;
		padding: 80px 0 30px;
		@include respond('phone-land'){
			padding: 50px 0 0px;
		}
	}
	.footer-bottom {
		background-color:$secondary;
		padding: 20px 0;
		font-size: 13px;
		border-top:1px solid rgba(255,255,255,0.25);
		@include respond('phone-land'){
			padding: 10px 0;
		}
		a{
			color:$ft-color;
		}
		.text-center{
			@include respond('phone-land'){
				padding-top:8px;
				padding-bottom:8px;
			}
		}
	}
	.widget-link{
		li{
			display: inline-block;
			text-transform: capitalize;
			margin-left: 0;
		}
		a{
			color:$ft-color;
		}
	}
	.recent-posts-entry .ttr-post-info .post-title{
		font-size:16px;
		font-weight:500;
	}
	.wp-block-latest-posts li, 
	.wp-block-categories-list li, 
	.wp-block-archives-list li, 
	.widget_categories ul li{
		&:after{
			background:$white;
		}
	}
	// Widget
	.wp-block-latest-posts li, 
	.wp-block-categories-list li, 
	.wp-block-archives-list li, 
	.widget_categories ul li, 
	.widget_archive ul li, 
	.widget_meta ul li, 
	.widget_pages ul li, 
	.widget_recent_comments ul li, 
	.widget_nav_menu li, 
	.widget_recent_entries ul li, 
	.widget_services ul li{
		border-bottom: 1px solid rgba(255,255,255,0.1);
		color:$ft-color;
		
		a{
			color: $ft-color;
			&:hover{
				color: lighten($white, 50);
			}
		}
		li{
			border-bottom: 1px solid rgba(255,255,255,0.1);
		}
		&:before{
			background:$ft-color ;
		}
	}
	.wp-block-latest-posts ul, 
	.wp-block-categories-list ul, 
	.wp-block-archives-list ul, 
	.widget_categories ul ul, 
	.widget_archive ul ul, 
	.widget_meta ul ul, 
	.widget_pages ul ul, 
	.widget_recent_comments ul ul, 
	.widget_nav_menu li ul, 
	.widget_services li ul{
	    border-top: 1px solid rgba(255,255,255,0.1);
	}
	.widget_recent_comments ul li{
		color:$ft-color;
	}
	.rss-date{
		color: lighten($primary, 50);
	}
	
	ul a.rsswidget:hover,
	.widget_text b,
	.widget_text strong,
	.wp-caption p.wp-caption-text,
	ul cite{
		color:$ft-color;
	}
	ul a.rsswidget,
	.footer-title a{
		color:$white;
	}
	.widget_calendar tr:nth-child(2n+2){
		background:darken($primary, 5);	
	}
	.widget_calendar caption,
	.wp-calendar-nav{
		background:darken($primary, 15);	
	}
	.widget_rss ul li{
	    border-bottom: 1px solid rgba(255,255,255,0.1);
	}
	.search-bx{
		border-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 0;
		.form-control{
			height: 45px;
			border-radius: 6px 0 0 6px;
		}
		.btn-primary{
		    background:darken($primary, 10);	
			border-color:darken($primary, 10);	
		}
	}
	.widget_tag_cloud a{
		background:darken($primary, 05);
		&:hover{
		background:darken($primary, 10);
		}
	}
	.widget_recent_comments ul li:before{
	    background: transparent;
		color:$ft-color;
	}
	.recent-posts-entry {
		.widget-post{
			border-bottom: 1px solid rgba(255,255,255,0.1);
		}
		.ttr-post-info .post-title a{
			color:$white;
		}
	}
}
.contact-info-bx{
	li{
		padding-bottom: 10px;
		position: relative;
		padding-left: 30px;
		padding-top: 2px;
		
		strong{
			font-weight:400;
		}
	}
	i{
		width: auto;
		height: 22px;
		line-height: 26px;
		text-align: center;
		border-radius: 30px;
		margin-right: 5px;
		position: absolute;
		top: 0;
		left: 0;
		font-size: 22px;
	}
}
.wp-block-button{
	margin-bottom:5px;
}
.wp-block-button__link{
	@extend .btn;
	@extend .btn-primary;
}
.is-style-outline .wp-block-button__link{
	@extend .btn-outline-primary;
}
.site-footer .footer-link li {
    display: inline;
}
.site-footer .footer-link > li > a{
	color: $ft-color;
    display: inline-block;
	i{
		@include respond('phone-land'){
			display:none;
		}
	}
}
.footerlink li ul{
	display:inline;
}
// footer
.footer-info {
    margin-bottom: 0;
    border-bottom: 0;
    padding: 40px 0 10px;
    margin-top: 0;
}
.footer-info-bx{
	display: flex;
	align-items: center;
	&.left{
		.icon-lg{
			width: 70px;
			max-width: 70px;
			min-width: 70px;
			margin-right: 15px;
			img{
				height: 60px;
			}
		}
	}
	.icon-content{
		p{
			line-height: 26px;
			font-size: 18px;
			font-family: Roboto Condensed;
			color: $white;
		}
	}
	@include respond('tab-land'){
		.icon-lg {
			width: 50px;
			max-width: 50px;
			min-width: 50px;
			img {
				height: 50px;
			}
		}
	}
}
/* Footer */
@media only screen and (min-width: 767px) {
	/* Footer Nav */
	.footer-link {
		.sub-menu {
			background-color: #ffffff;
			border: 0;
			display: block;
			left: 0;
			list-style: none;
			opacity: 0;
			padding: 10px 5px;
			position: absolute;
			visibility: hidden;
			width: 180px;
			z-index: 10;
			padding: 10px 0;
			margin-bottom: 15px;
			@include transitionMedium;
			bottom: 100%;
			box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
			text-align: left;
			li {
				a {
					color: #555;
					display: block;
					font-size: 15px;
					padding: 8px 0;
					text-transform: capitalize;
					@include transitionMedium;
					font-weight: 500;
					position: relative;
					&:hover {
						color: $primary;
					}
				}
				&:last-child {
					a {
						border-bottom: 0;
					}
				}
				padding: 0 20px;
				display: block;
			}
			.sub-menu {
				left: 180px;
				top: 0;
				bottom: auto;
			}
		}
		li {
			&.menu-item-has-children > a > i{
				font-size:11px;
			}
			&:hover {
				>.sub-menu {
					opacity: 1;
					visibility: visible;
					margin-bottom: 5px;
				}
			}
		}
	}
}

.widget{
	&.widget-link-2{
		ul{
			display: flex;
			flex-wrap: wrap;
			li{
				width: 50%;
				padding: 0;
				a{
					color:$ft-color;
					display: block;
					padding: 5px 0;
					@include transitionMedium;
					&:hover{
						color: #fff;
					}
				}
			}
		}
	}
	.ft-social-bx{
		li{
			margin-right: 5px;
			a{
				background: $white;
				color: $primary;
				height: 40px;
				width: 40px;
				border-radius: 50px;
				padding: 0;
				line-height: 40px;
				&:hover{
					background-color: $primary;
					color: #fff;
				}
			}
		}
	}
	.subscribe-form{
		.form-control{
			background-color: rgba($white,0.05);
			border: 1px solid;
			border-color: rgba($white,0.1);
			height: 40px;
			border-radius: 0;
			&::placeholder{
				color:$ft-color;
				font-size: 14px;
			}
		}
	}
	.ft-content{
		background-color: rgba($white,0.05);
		border: 1px solid;
		border-color: rgba($white,0.1);
		padding: 15px 15px 15px 80px;
		position: relative;
		
		i{
			background: $primary;
			height: 50px;
			width: 50px;
			color: $white;
			line-height: 50px;
			text-align: center;
			font-size: 24px;
			border-radius: 50px;
			position: absolute;
			left: 15px;
			&:after{
				content: "";
				position: absolute;
				right: -9px;
				top: 50%;
				transform: rotate(45deg) translateX(-50%);
				height: 15px;
				width: 15px;
 				background: $primary;
			}
		}
		span{
			font-size: 14px;
			display: block;
			margin-bottom: 5px;
		}
		h4{
			color: $white;
			font-size: 22px;
			margin-bottom: 0;
			line-height: 24px;
		}
	}
}





















